import {Card, Col, Pagination, Row, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import React, {useMemo, useState} from 'react';
import {PaginationConfig} from 'antd/lib/pagination';
import {ColumnsType} from 'antd/lib/table';
import {useGetUogContactsPaginatedQuery} from '../../redux/api/uogContact';
import {UogContact} from '../../types/api/uogContact';
import {useAssetDetails} from '../../context/assetDetails/AssetDetails';
import {capitalize} from 'lodash';

const UOGTable = () => {
  const {t} = useTranslation();
  const [, {asset}] = useAssetDetails();
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'right'},
  });
  
  const {
    data: uogContacts,
    isFetching: isFetchingUogContacts,
    isUninitialized: isUninitializedUogContacts,
  } = useGetUogContactsPaginatedQuery({
    page: pagination.current,
    page_size: pagination.pageSize,
    sigi_code: [asset.sigi_code],
  });
  
  const columns: ColumnsType<UogContact> = useMemo(() => [
    {
      title: capitalize(t('contacts.fullName')),
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: capitalize(t('contacts.personInCharge')),
      dataIndex: 'person_in_charge',
      key: 'person_in_charge',
      render: value => value ? 'SÌ' : 'NO',
    },
    {
      title: t('contacts.uogCode'),
      dataIndex: 'uog_code',
      key: 'uog_code',
    },
    {
      title: capitalize(t('contacts.company')),
      key: 'company_name',
      render: value => <div style={{whiteSpace: 'nowrap'}}>{value.company.name}</div>,
    },
    {
      title: t('contacts.soaCode'),
      key: 'soa_code',
      render: value => <div style={{whiteSpace: 'nowrap'}}>{value.company.soa_code}</div>,
    },
  ], [t]);
  
  return (
    <Card title={capitalize(t('contacts.staff'))}>
      <Table
        <UogContact>
        loading={isFetchingUogContacts || isUninitializedUogContacts}
        dataSource={uogContacts?.results}
        rowKey={'uuid'}
        columns={columns}
        bordered={false}
        scroll={{x: 576}}
        pagination={false}
      />
      <Row
        justify={'center'}
        style={{marginTop: '1rem'}}
      >
        <Col>
          <Pagination
            total={uogContacts?.count}
            {...pagination}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default UOGTable;