import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import {Button, Card, Space, Typography} from 'antd';
import {
  useCreateReiLayerMutation,
  useDeleteLayerMutation,
  useEnableLayerBucketMutation,
  useLayerUploadToS3Mutation,
  useRequestS3LayerUploadMutation,
  useUpdateLayerMutation,
} from '../../../redux/api/layers';
import {CustomLoading} from '../../../components/common/CustomLoading';
import {CheckCircleOutlined, WarningOutlined, WarningTwoTone} from '@ant-design/icons';

const useUploadLayerToS3 = () => {
  
  const [requestLayerUploadToS3] = useRequestS3LayerUploadMutation();
  const [uploadLayerToS3] = useLayerUploadToS3Mutation();
  const [enableLayer] = useEnableLayerBucketMutation();
  const [deleteLayer] = useDeleteLayerMutation();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);
  
  const uploadS3 = ({description, frontend_name, layer_source, layer_file}: {
    description: string,
    frontend_name: string,
    layer_source: string,
    layer_file: { file: File, fileList: any[] },
  }) => {
    if (layer_file.file.size / 1000000 > 15) {
      setIsLoading(true);
      setIsError(undefined);
      setIsSuccess(undefined);
      requestLayerUploadToS3({ //create_layer_bucket
        description,
        frontend_name,
        layer_source: layer_source,
      })
        .unwrap()
        .then((requestResponse) => {
          const postData = new FormData();
          for (const [key, value] of Object.entries(requestResponse.fields)) {
            postData.append(key, value);
          }
          postData.append('file', layer_file.file);
          uploadLayerToS3({url: requestResponse.url, postData})
            .unwrap()
            .then((() => {
              enableLayer(requestResponse.uuid)
                .unwrap()
                .then((response) => {
                  setIsSuccess(true);
                  setIsLoading(false);
                })
                .catch(() => {
                  console.error('Impossibile finalizzare l\'upload del layer');
                  deleteLayer({uuid: requestResponse.uuid})
                    .unwrap()
                    .finally(() => {
                      setIsError(true);
                      setIsLoading(false);
                    });
                  
                });
            }))
            .catch(() => {
              console.error('Impossibile caricare il layer.');
              deleteLayer({uuid: requestResponse.uuid})
                .unwrap()
                .finally(() => {
                  setIsError(true);
                  setIsLoading(false);
                });
            });
        })
        .catch(() => {
          console.error('Impossibile richiedere il caricamento per il layer.');
          setIsError(true);
          setIsLoading(false);
        });
      
    } else {
      console.error('Il file va caricato su rei e non su bucket s3.');
    }
  };
  
  return {
    upload: uploadS3,
    isLoading: isLoading,
    isError: isError,
    isSuccess: isSuccess,
  };
};

function GoToLayersSettings() {
  
  const navigate = useNavigate();
  const {t} = useTranslation();
  
  return <Button
    type={'primary'}
    onClick={() => navigate('/settings/layers')}
  >
    {capitalize(t('actions.goBackDestination', {destination: t('layers.layersManagement')}))}
  </Button>;
}

function Loading() {
  const {t} = useTranslation();
  
  return <>
    <CustomLoading style={{fontSize: 150}}/>
    <Typography.Title level={2} style={{margin: 0}}>{capitalize(t('layers.upload.loadingTitle'))}</Typography.Title>
    <Space align={'center'}>
      <WarningTwoTone/>
      <Typography.Paragraph
        style={{margin: 0}}
      >{capitalize(t('layers.upload.loadingDescription'))}</Typography.Paragraph>
    </Space>
  </>;
}

function Success() {
  
  const {t} = useTranslation();
  
  return <>
    <CheckCircleOutlined style={{fontSize: 150, color: '#52C41A'}}/>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      <Typography.Title
        level={2} style={{margin: 0}}
      >{capitalize(t('layers.upload.successTitle'))}</Typography.Title>
      <Typography.Paragraph
        style={{margin: 0}}
      >{capitalize(t('layers.upload.successDescription'))}</Typography.Paragraph>
    </div>
    <div>
      <GoToLayersSettings/>
    </div>
  </>;
}

function Error() {
  
  const {t} = useTranslation();
  
  return <>
    <WarningOutlined style={{fontSize: 150, color: '#f20404'}}/>
    <Typography.Title level={2} style={{margin: 0}}>{capitalize(t('layers.upload.errorTitle'))}</Typography.Title>
    <div>
      <GoToLayersSettings/>
    </div>
  </>;
}

export default function UploadLayer() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const body = location.state;
  
  const {
    upload: uploadS3,
    isLoading: isS3UploadLoading,
    isSuccess: isS3UploadSuccess,
    isError: isS3UploadError,
  } = useUploadLayerToS3();
  
  const willMountRef = useRef(true);
  
  const [createReiLayer, {
    isSuccess: reiSuccess,
    isError: reiError,
    isLoading: reiLoading,
  }] = useCreateReiLayerMutation();
  const [updateLayer, {
    isSuccess: updateSuccess,
    isError: updateError,
    isLoading: updateLoading,
  }] = useUpdateLayerMutation();
  
  useEffect(() => {
    if (body?.type === 'edit' && updateLayer) {
      const {type, ...params} = body;
      updateLayer(params);
    }
  }, [body, updateLayer]);
  
  useEffect(() => {
    if (willMountRef.current && body?.type === 'creation' && createReiLayer) {
      const {type, layer_file, ...params} = body;
      if (layer_file.file.size / 1000000 > 15) {
        uploadS3({...body});
      } else {
        createReiLayer({...params, layer_file: layer_file.file});
      }
      willMountRef.current = false;
    }
  }, [body, createReiLayer, uploadS3]);
  
  useEffect(() => {
    if (!body?.type && navigate) {
      navigate('/');
    }
  }, [body?.type, navigate]);
  
  return <ResponsiveContent
    goBack={() => navigate('/settings/layers')}
    goBackText={capitalize(t('layers.layersManagement'))}
  >
    <Card
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '2.5rem',
        alignItems: 'center',
      }}
    >
      
      {updateLoading && <Loading/>}
      {updateSuccess && <Success/>}
      {updateError && <Error/>}
      
      {(reiLoading || isS3UploadLoading) && <Loading/>}
      {(reiSuccess || isS3UploadSuccess) && <Success/>}
      {(reiError || isS3UploadError) && <Error/>}
    
    </Card>
  </ResponsiveContent>;
}
/*
ricapitolando:
se la dimensione del file .zip è > 15MB
  -creo il bucket tramite create_layer_bucket
    -se errore: "Impossibile richiedere il caricamento per il layer."
    -se ok: ottengo il link per la presigned post
      -utilizzo il link presigned per fare l'upload
        -effettuo l'upload
          -se errore: "Impossibile caricare il layer"
          -se ok: effettuo la patch su enable_layer_bucket con l'uuid del layer
            -se errore: "Impossibile finalizzare l'upload del layer"
            -se ok: "layer caricato"
    
altrimenti:
  mantengo il funzionamento attuale
*/