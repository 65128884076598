import {apiSlice} from './baseApi';
import {GenericPaginatedApi} from '../../types/api/paginatedApi';
import {GetUogContactParams, UogContact} from '../../types/api/uogContact';

const uogContactSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUogContactsPaginated: build.query<GenericPaginatedApi<UogContact>, GetUogContactParams>({
      query: (params) => {
        return {
          url: '/uog-contact/',
          params: {
            ...params,
            sigi_code: params.sigi_code?.join(','),
            soa_code: params.soa_code?.join(','),
            uog_code: params.uog_code?.join(','),
            paginate: true,
          },
        };
      },
    }),
  }),
});
export const {useGetUogContactsPaginatedQuery} = uogContactSlice;